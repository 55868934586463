<style lang="scss" scoped>
</style>
<template>
  <el-card header="指标数据查询">
    <el-row type="flex" :gutter="20">
      <el-menu class="small-nav" @select="fetch" :default-active="defaultMenu">
        <el-menu-item :index="s.code" v-for="s in system" :key="s.code">{{s.code}} {{s.name}}</el-menu-item>
      </el-menu>
      <el-col>
        <el-table
          border
          style="width: 100%"
          size="small"
          :data="data"
          v-loading="loading"
          stripe
          empty-text="没有数据"
          >
            <el-table-column label="指标" width="180px" fixed>
              <span slot-scope="scope">{{scope.row.index.code}} {{scope.row.index.name}}</span>
            </el-table-column>
            <el-table-column label="单位" width="80px" fixed>
              <span slot-scope="scope">{{scope.row.index.input === 1 ? scope.row.index.measure : '指数'}}</span>
            </el-table-column>
            <el-table-column v-for="(round, index) in rounds" :key="round.id" width="110px">
              <template slot="header">
                <round :round="round.round" :type="round.type"/>
              </template>
              <span slot-scope="scope">{{scope.row.data[index]}}</span>
            </el-table-column>
          </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      defaultMenu: '',
      loading: true,
      inputs: [],
      rounds: [],
      data: []
    }
  },
  computed: {
    // ...mapState('indexSystem', ['system']),
    ...mapState('account', { account: 'user' }),
    system () {
      return this.$store.state.indexSystem.system.filter(i => !i.total && i.inputs)
    }
  },
  updated () {
    console.log('updated')
  },
  methods: {
    fetch (code) {
      this.loading = true
      this.$request('/index/inputs', { params: { gp: code } })
        .then(({ data, rounds }) => {
          this.data = data
          this.rounds = rounds
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    const code = this.system[0].code
    this.defaultMenu = code
    this.fetch(code)
  }
}
</script>
