<template>
  <el-card>
    <div slot="header">
      <el-form inline size="small"  @submit.native.prevent="subQuery">
        <el-form-item label="报表">
          <el-select v-model="params.tableId" placeholder="请选择" style="width: 180px">
            <el-option
              v-for="item in tables"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="期次">
          <season-picker v-model="params.round" format="yyyy年q季度" value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit">查询</el-button>
          <el-button type="primary" @click="exp">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="showTable" >
      <el-table :data="list" stripe style="width: 100%" v-loading="loading" :fit="false" empty-text="没有数据">
        <el-table-column v-if="account.role !== 'sample'" label="企业" min-width="240" fixed>
          <div slot-scope="scope" class="sample-row">
            <div class="sample" @click="$showSample(scope.row.sample.id)">{{scope.row.sample.name}}</div>
            <div class="link"><el-link @click="$showReport(scope.row.report.id)"><i class="el-icon-link" style="margin-right: 3px; color: #888;">查看</i></el-link></div>
          </div>
        </el-table-column>
        <nested-columns v-for="info in table ? table.rows : []" :key="info.id" :info="info" :matrix="table.matrix" />
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </div>
  </el-card>
</template>
<script>
import { mapState } from 'vuex'
import SeasonPicker from '../../components/SeasonPicker.vue'
import listPageQuery from '../../mixins/list_page_query'

export default {
  data () {
    return {
      url: '/reports/preview',
      table: null,
      showTable: false
    }
  },
  mixins: [listPageQuery],
  components: { SeasonPicker },
  computed: {
    ...mapState('account', { account: 'user' }),
    ...mapState('tables', { tables: 'list' })
  },
  methods: {
    subQuery () {
      if (!this.params.tableId) return this.$message('请选择报表')
      if (!this.params.round) return this.$message('请选择期次')
      this.showTable = false
      this.requestData({ ...this.params, current: 1 }).then((res) => {
        this.showTable = true
        this.table = res.table
      })
    },
    exp () {
      if (!this.params.tableId) return this.$message('请选择报表')
      if (!this.params.round) return this.$message('请选择期次')
      window.location.href = `/api/reports/preview/excel?tableId=${this.params.tableId}&round=${this.params.round}`
    }
  },
  mounted () {
    // this.search()
    // this.fetch()
  }
}
</script>
<style scoped>
  .sample-row { display: flex;}
  .sample-row .sample {
    flex: 1 0 0%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis; cursor: pointer;
  }
  .sample-row .sample:hover {
    color: #3963bc;
    text-decoration: underline;
  }
  .sample-row .link { width: 50px; text-align: right; }
</style>
