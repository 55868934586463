<style lang="scss" scoped>

</style>
<template>
  <el-card header="指数查询">
    <el-row :gutter="16">
      <el-col :span="5">
        <div style="border-right: 1px solid #eee;">
          <el-tree node-key="id" :default-expanded-keys="[1]" :expand-on-click-node="false" :data="tree" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </div>
      </el-col>
      <el-col :span="19">
        <div style="height: 300px;" ref="chart"></div>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import { mapState } from 'vuex'
import echarts from '../../plugins/echarts'

export default {
  data () {
    return {
      tree: null,
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  computed: {
    ...mapState('account', { account: 'user' })
  },
  methods: {
    handleNodeClick (e) {
      console.log(e)
      this.$request('/index/data', { params: { ids: [e.id] } })
        .then(res => {
          const option = {
            grid: { left: '50px', right: '34px', top: '20px', bottom: '50px' },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              show: true,
              bottom: 0
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: res.rounds
            },
            yAxis: {
              type: 'value',
              min: (value) => value.min - 20
            },
            series: res.index.map(i => ({
              name: i.info.name,
              type: 'line',
              data: i.data
            }))
          }
          this.chart.setOption(option)
        })
    }
  },
  created () {
    this.$request('/index/system/tree')
      .then(res => {
        this.tree = res
        this.handleNodeClick({ id: res[0].id })
      })
  },
  mounted () {
    this.chart = echarts.init(this.$refs.chart)
  }
}
</script>
