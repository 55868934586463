<template>
  <loading-content :loading="loading" :error="error">
    <el-row :gutter="16">
      <el-col :span="16">
        <el-card>
          <div slot="header">
            指数预览
            <div style="float: right;">
              <el-link @click="$router.push('/indexes')">更多<i class="el-icon-arrow-right el-icon--right"></i></el-link>
            </div>
          </div>
          <div style="height: 270px;" ref="indexChart1"></div>
        </el-card>
        <el-row :gutter="10">
          <el-col :span="12" v-for="item in topSystem" :key="item.id" style="width: 50%;">
            <el-card>
              <div slot="header">{{item.code}} {{item.name}}</div>
              <div style="height: 250px; width: 100%;" :ref="'subIndexChart' + item.id"></div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" v-if="previewInfo">
        <el-card>
          <div slot="header" class="clearfix">
            <span style="margin-right: 10px;">企业上报进度</span>
            <el-button icon="el-icon-refresh" circle size="mini" @click="fetchPreview" />
            <div style="float: right;">
              <el-link @click="$router.push('/progress')">查看<i class="el-icon-arrow-right el-icon--right"></i></el-link>
            </div>
          </div>
          <div v-for="(item) in previewInfo.rounds" :key="item.id" class="round-chart">
            <div class="round">
              <round :type="item.type" :round="item.round" />
              <span class="end-time">截止填报日期：<date-time :time="item.endAt" :showTime="false"/></span>
            </div>
            <div class="row">
              <div style="width: 170px; height: 170px;" :ref="'chartRound' + item.id + '_1'"></div>
              <div class="col">
                <el-divider>填报进度</el-divider>
                <div class="count">已上报<b>{{item.info.reports}}</b>张</div>
                <div class="count">未上报<b>{{item.info.total - item.info.reports}}</b>张</div>
                <div class="count">共需上报<b>{{item.info.total}}</b>张</div>
              </div>
            </div>
            <div class="row">
              <div style="width: 170px; height: 170px;" :ref="'chartRound' + item.id + '_2'"></div>
              <div class="col">
                <el-divider>审核进度</el-divider>
                <div class="count">已审核<b>{{item.info.reviews[1]}}</b>张</div>
                <div class="count">未审核<b>{{item.info.reviews[0]}}</b>张</div>
                <div class="count">审核不通过<b>{{item.info.reviews[-1]}}</b>张</div>
              </div>
            </div>
            <div class="row flex-center">
              <div style="width: 170px; height: 170px;" :ref="'chartRound' + item.id" class="pie"></div>
              <div class="col">
                <el-divider>报表统计</el-divider>
                <div v-for="tb in item.tables" :key="tb.id" class="tb">
                  <div class="table">{{tb.shortName}}</div>
                  <div class="count">已上报<b>{{tb.info.reports}}</b>张 / 总共<b>{{tb.info.total}}</b>张</div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card>
          <div slot="header">
            <span style="margin-right: 10px;">最近填报</span>
            <el-button icon="el-icon-refresh" circle size="mini" @click="fetchReports" />
            <div style="float: right;">
              <el-link @click="$router.push('/progress')">更多<i class="el-icon-arrow-right el-icon--right"></i></el-link>
            </div>
          </div>
          <div v-for="p in reports.list" :key="p.id" class="reports">
            <div class="info">
              <date-time :time="p.report.updateAt" showTime />
              <el-link @click="$showSample(p.sample.id)">{{p.sample.name}}</el-link>
            </div>
            <div @click="$showReport(p.report.id)">
              <el-link><i class="el-icon-link" />{{p.table.shortName}}</el-link>
              <review-status :status="p.report.reviewStatus" :comment="p.report.reviewComment" />
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </loading-content>
</template>
<script>
import { mapState } from 'vuex'
// import np from 'number-precision'
import echarts from '../plugins/echarts'
import { listIndexes } from '../plugins/chart-options'

export default {
  name: 'HomeAdmin',
  data () {
    return {
      loading: true,
      error: null,
      previewInfo: null,
      reports: {
        list: [],
        error: null,
        loading: true
      }
    }
  },
  computed: {
    ...mapState('indexSystem', ['system']),
    ...mapState('account', { account: 'user' }),
    topSystem () {
      return this.system.filter(i => !i.total)
    }
  },
  methods: {
    // 上报进度和审核进图
    getPieOption1 (data, percent) {
      return {
        tooltip: { trigger: 'item', position: ['20%', '20%'] },
        legend: {
          show: false
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            label: {
              show: true,
              position: 'center',
              color: '#4c4a4a',
              formatter: '{count|' + percent + '}{percent|%}',
              rich: {
                count: {
                  fontWeight: 'bold',
                  fontSize: 24,
                  color: '#454c5c'
                },
                percent: {
                  fontSize: 22,
                  color: '#555'
                }
              }
            },
            emphasis: {
              label: { show: true }
            },
            labelLine: {
              show: true
            },
            data
          }
        ]
      }
    },
    // 各个期次的进度
    getPieOptionRound (data, data2) {
      return {
        tooltip: { trigger: 'item' },
        series: [
          {
            type: 'pie',
            radius: ['20%', '40%'],
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            data
          }, {
            type: 'pie',
            radius: ['50%', '70%'],
            label: { show: false },
            labelLine: { show: false },
            data: data2
          }
        ]
      }
    },
    getChart (key) {
      this.charts = this.charts || {}
      if (this.charts[key]) return this.charts[key]
      const rf = this.$refs[key]
      this.charts[key] = echarts.init(rf instanceof Array ? rf[0] : rf)
      return this.charts[key]
    },
    renderProgressChart () {
      const { rounds } = this.previewInfo
      // 开放期次图表
      rounds.forEach((item) => {
        // 上报进度图表
        const option1 = this.getPieOption1(
          [{ value: item.info.total - item.info.reports, name: '未填报' }, { value: item.info.reports, name: '已填报' }],
          item.info.total === 0 ? 0 : Math.round(100 * item.info.reports / item.info.total)
        )
        this.getChart('chartRound' + item.id + '_1').setOption(option1)
        // 审核进度图表
        const t2 = item.info.reviews[0] + item.info.reviews[1] + item.info.reviews[-1]
        const option2 = this.getPieOption1([
          { value: item.info.reviews[0], name: '未审核' },
          { value: item.info.reviews[-1], name: '审核不通过' },
          { value: item.info.reviews[1], name: '通过审核' }
        ], Math.round(t2 === 0 ? 0 : 100 * item.info.reviews[1] / t2))
        this.getChart('chartRound' + item.id + '_2').setOption(option2)

        const data = [
          { value: item.info.total - item.info.reports, name: '未上报' },
          { value: item.info.reports, name: '已上报' }
        ]
        const data1 = [
          { value: item.info.reviews[0], name: '未审核' },
          { value: item.info.reviews[-1], name: '审核不通过' },
          { value: item.info.reviews[1], name: '通过审核' }
        ]
        this.getChart('chartRound' + item.id).setOption(this.getPieOptionRound(data, data1))
      })
    },
    fetchPreview () {
      this.$request('/progress/preview')
        .then(res => {
          // const { info: { reports, reviews, total }, rounds } = res
          this.previewInfo = res
          this.loading = false
          this.$nextTick(this.renderProgressChart)
        })
    },
    fetchIndex () {
      // const ids = this.system.filter(i => !i.total).map(i => i.id)
      const ids = this.system.map(i => i.id)
      this.$request('/index/data', { params: { ids: ids } })
        .then(res => {
          const option = listIndexes(false, { rounds: res.rounds, indexes: res.index })
          this.$nextTick(() => {
            this.getChart('indexChart1').setOption(option)
          })
        })
    },
    fetchSubIndex (id, name) {
      this.$request('/index/data/subs', { params: { id: id } })
        .then(res => {
          const { rounds, index: [total, ...index] } = res
          const legendSelected = { }
          legendSelected[`${total.info.code}${total.info.name}`] = false
          const option = listIndexes(false, { rounds, indexes: index })
          // console.log(total, index)
          // const option = {
          //   grid: { left: '40px', right: '34px', top: '20px', bottom: '92px' },
          //   tooltip: {
          //     trigger: 'axis',
          //     axisPointer: {
          //       type: 'shadow'
          //     }
          //   },
          //   legend: {
          //     show: true,
          //     bottom: 0,
          //     selected: legendSelected
          //   },
          //   xAxis: {
          //     type: 'category',
          //     // boundaryGap: true,
          //     boundaryGap: true,
          //     data: rounds
          //   },
          //   yAxis: [
          //     {
          //       type: 'value'
          //     }
          //   ],
          //   series: [
          //     {
          //       type: 'line',
          //       smooth: true,
          //       symbolSize: 3,
          //       symbol: 'circle',
          //       name: total.info.code + total.info.name,
          //       data: total.data
          //     },
          //     ...index.map((i, index) => ({
          //       name: i.info.code + i.info.name,
          //       type: 'bar',
          //       stack: 'total',
          //       barMaxWidth: '15px',
          //       barGap: '34%',
          //       tooltip: {
          //         formatter () {
          //           return 'xxxx'
          //         },
          //         valueFormatter: (value) => np.round(value / i.info.weight, 2)
          //       },
          //       data: i.data.map(v => np.round(v * i.info.weight, 2))
          //     }))
          //   ]
          // }
          this.$nextTick(() => {
            this.getChart('subIndexChart' + id).setOption(option)
          })
        })
    },
    resize () {
      for (const i in this.charts) {
        this.charts[i].resize()
      }
    },
    fetchReports () {
      this.$request('/progress?status=1&size=10&order=time')
        .then(res => {
          this.reports.list = res.list
          this.reports.error = null
          this.reports.loading = false
        })
        .catch(err => {
          this.reports.error = err
          this.reports.loading = false
        })
    }
  },
  created () {
    this.fetchPreview()
    this.fetchIndex()
    setTimeout(() => {
      this.topSystem.forEach(i => this.fetchSubIndex(i.id, i.name))
    }, 1000)
    this.fetchReports()
  },
  mounted () {
    window.addEventListener('resize', this.resize)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
<style scoped lang="scss">
.reports {
  padding: 6px 0; margin-bottom: 4px;
  span { display: inline-block; vertical-align: middle; }
  line-height: 18px !important;
  border-bottom: 1px solid #EBEEF5;
  &:last-child { border-bottom: none; }
  a { color: #303133; }
  .info {
    margin-bottom: 4px;
    .date-time { color: #606266; margin-right: 8px; }
  }
}
.card-header {
  b { font-weight: bold; margin: 0 3px;}
}
.counts {
  white-space: nowrap;
  div { line-height: 2em; b { font-weight: bold; font-size: 16px; margin: 0 4px; } }
}

::v-deep{
  .el-divider__text.is-center { white-space: nowrap !important;}
  .rounds-card { .el-card__body { padding-top: 0; } }
  .el-card { margin-bottom: 12px; }
}
.round-chart {
  &:not(:last-child) { border-bottom: 1px solid #EBEEF5; }
  .round {
    font-size: 16px; font-weight: 700; margin-bottom: 12px;
    .end-time { margin-left: 8px; font-size: 12px; color: #616161; }
    }
  .count { font-size: 13px; color: #616161; line-height: 1.7em; b { font-weight: bold; margin: 0 4px; }  }
  .table { line-height: 1.7em; }
}
.el-link { font-weight: 400 !important; }
.row {
  display: flex; margin-left: -16px; margin-top: -16px;
  >* { padding-left: 16px; padding-top: 16px; box-sizing: border-box;}
  .col { flex: 1; }
}
</style>
