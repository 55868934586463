
// import { ref } from "vue"
import { graphic } from 'echarts'
// import reduce from 'lodash/reduce'
export const indexesMain = function (isDark, rounds, data) {
  function graphicFactory (side) {
    return {
      type: 'text',
      bottom: '8',
      ...side,
      style: {
        text: '',
        textAlign: 'center',
        fill: '#4E5969',
        fontSize: 12
      }
    }
  }
  // const graphicElements = ref([
  //   graphicFactory({ left: '2.6%' }),
  //   graphicFactory({ right: 0 })
  // ])
  const graphicElements = [
    graphicFactory({ left: '2.6%' }),
    graphicFactory({ right: 0 })
  ]
  return {
    grid: {
      left: '30',
      right: '0',
      top: '5',
      bottom: '30'
    },
    xAxis: {
      type: 'category',
      offset: 2,
      data: rounds,
      boundaryGap: false,
      axisLabel: {
        color: '#4E5969',
        formatter (value, idx) {
          if (idx === 0) return `{r|${value}}`
          if (idx === rounds.length - 1) return `{l|${value}}`
          return `${value}`
        },
        width: 148,
        height: 20,
        lineHeight: 20,
        rich: {
          l: { align: 'left' },
          r: { align: 'right' }
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: true,
        interval: (idx) => {
          if (idx === 0) return false
          if (idx === rounds.length - 1) return false
          return true
        },
        lineStyle: {
          color: isDark.value ? '#484849' : '#E5E8EF'
        }
      },
      axisPointer: {
        show: true,
        lineStyle: {
          color: '#23ADFF',
          width: 2
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisLabel: {
        // formatter(value, idx) {
        //   if (idx === 0) return value;
        //   return `${value}k`;
        // },
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: isDark.value ? '#484849' : '#E5E8EF'
        }
      },
      min (v) {
        return Math.floor((v.min - 20) / 10) * 10
      }
    },
    // tooltip: {
    //   trigger: 'axis',
    //   formatter(params) {
    //     const [firstElement] = params as ToolTipFormatterParams[];
    //     return `<div>
    //       <p class="tooltip-title">${firstElement.axisValueLabel}</p>
    //       <div class="content-panel"><span>总内容量</span><span class="tooltip-value">${(
    //         Number(firstElement.value) * 10000
    //       ).toLocaleString()}</span></div>
    //     </div>`;
    //   },
    //   className: 'echarts-tooltip-diy',
    // },
    graphic: {
      elements: graphicElements
    },
    series: [
      {
        data,
        type: 'line',
        smooth: true,
        // symbol: 'circle',
        symbolSize: 12,
        emphasis: {
          focus: 'series',
          itemStyle: {
            borderWidth: 2
          }
        },
        lineStyle: {
          width: 3,
          color: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 0,
              color: 'rgba(30, 231, 255, 1)'
            },
            {
              offset: 0.5,
              color: 'rgba(36, 154, 255, 1)'
            },
            {
              offset: 1,
              color: 'rgba(111, 66, 251, 1)'
            }
          ])
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(17, 126, 255, 0.16)'
            },
            {
              offset: 1,
              color: 'rgba(17, 128, 255, 0)'
            }
          ])
        }
      }
    ]
  }
}

export const indexesRadar = function (isDark, data) {
  const count = 3
  const indexes = data.indexes.slice(1)
  const rounds = data.rounds.slice(-count)

  const color = [
    { color: isDark.value ? '#6CAAF5' : '#249EFF' },
    { color: isDark.value ? '#A079DC' : '#313CA9' },
    { color: isDark.value ? '#3D72F6' : '#21CCFF' }
  ]
  const d = rounds.map((i, index) => ({
    name: i,
    value: indexes.map(i => i.data[i.data.length - 3 + index]),
    symbol: 'none',
    itemStyle: color[index]
  }))
  const max = 100
  // const max = Math.max(...reduce(d, (memo, v) => ([...memo, ...v.value]), []))
  const indicator = indexes.map(i => ({ name: i.system.name, max: Math.floor((max + 20) / 10) * 10 }))
  return {
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    legend: {
      show: true,
      top: 'center',
      right: 5,
      orient: 'vertical',
      icon: 'circle',
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 20,
      textStyle: {
        color: isDark.value ? '#eee' : '#4E5969'
      }
    },
    radar: {
      center: ['38%', '50%'],
      radius: 90,
      indicator,
      axisName: {
        color: isDark.value ? '#eee' : '#1D2129'
      },
      axisLine: {
        lineStyle: {
          color: isDark.value ? '#484849' : '#E5E6EB'
        }
      },
      splitLine: {
        lineStyle: {
          color: isDark.value ? '#484849' : '#E5E6EB'
        }
      },
      splitArea: {
        areaStyle: {
          color: []
        }
      }
    },
    series: [
      {
        type: 'radar',
        areaStyle: {
          opacity: 0.2
        },
        data: d
      }
    ]
  }
}

export const reportsPie = function (isDark, done, undone) {
  const graphicElementStyle = {
    textAlign: 'center',
    fill: isDark.value ? 'rgba(255,255,255,0.7)' : '#4E5969',
    fontSize: 14,
    lineWidth: 10,
    fontWeight: 'bold'
  }
  return {
    // grid: {
    //   top: '20px',
    //   bottom: '20px'
    // },
    legend: {
      show: true,
      left: 'center',
      data: ['已填报', '待填报'],
      bottom: 0,
      icon: 'circle',
      itemWidth: 8,
      textStyle: {
        color: isDark.value ? 'rgba(255,255,255,0.7)' : '#4E5969'
      },
      itemStyle: {
        borderWidth: 0
      }
    },
    tooltip: {
      show: true,
      trigger: 'item'
    },
    graphic: {
      elements: [
        {
          type: 'text',
          left: 'center',
          top: '43%',
          style: {
            text: Math.floor(done * 100 / (done + undone)) + '%',
            ...graphicElementStyle
          }
        }
      ]
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['50%', '46%'],
        label: {
          formatter: '{d}% ',
          color: isDark.value ? 'rgba(255, 255, 255, 0.7)' : '#4E5969'
        },
        itemStyle: {
          borderColor: isDark.value ? '#000' : '#fff',
          borderWidth: 1
        },
        data: [
          {
            value: [done],
            name: '已填报',
            itemStyle: {
              color: '#249EFF'
            }
          },
          {
            value: [undone],
            name: '待填报',
            itemStyle: {
              color: '#846BCE'
            }
          }
        ]
      }
    ]
  }
}

export const indexesBar = function (isDark, data) {
  const { rounds, indexes } = data
  const option = {
    // color: ['#468DFF', '#86DF6C'],
    color: isDark.value ? ['#6CAAF5', '#A079DC'] : ['#468DFF', '#313CA9'],
    grid: {
      top: 20,
      bottom: 60,
      left: 20,
      right: 5
    },
    legend: {
      show: true,
      bottom: 0,
      textStyle: {
        color: isDark.value ? '#eee' : '#4E5969'
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisLabel: {
        show: false
        // formatter(value, idx) {
        //   if (idx === 0) return value;
        //   return `${value}k`;
        // },
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: isDark.value ? '#484849' : '#E5E8EF'
        }
      }
    },
    xAxis: {
      type: 'category',
      offset: 2,
      data: rounds,
      boundaryGap: true,
      axisLabel: {
        color: '#4E5969'
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: isDark.value ? '#484849' : '#E5E8EF'
        }
      }
    },
    series: indexes
      .filter(i => i.system.code === 'O' || i.system.code === 'S')
      .map((i, index) => ({ type: 'bar', barWidth: 20, name: i.system.name, data: i.data }))
  }
  return option
}

export const listIndexes = function (isDark, data) {
  const { rounds, indexes } = data
  // const indexes = data.indexes.filter(i => ['A1', 'A2', 'A3', 'A4'].indexOf(i.system.code) >= 0)
  const colors = [
    ['#722ED1', '#F5E8FF'],
    ['#F77234', '#FFE4BA'],
    ['#33D1C9', '#E8FFFB'],
    ['#3469FF', '#E8F3FF']
  ]
  let colorIndex = 0
  const generateSeries = (name, data) => {
    const lineColor = colors[colorIndex % 4][0]
    const itemBorderColor = colors[colorIndex % 4][1]
    const m = {
      name,
      data,
      // stack: 'Total',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 10,
      itemStyle: {
        color: lineColor
      },
      emphasis: {
        focus: 'series',
        itemStyle: {
          color: lineColor,
          borderWidth: 2,
          borderColor: itemBorderColor
        }
      },
      lineStyle: {
        width: 2,
        color: lineColor
      },
      showSymbol: false,
      areaStyle: {
        opacity: 0.1,
        color: lineColor
      }
    }
    colorIndex++
    return m
  }
  const option = {
    // grid: {
    //   left: '40px',
    //   right: '4',
    //   top: '5',
    //   bottom: '60'
    // },
    grid: { left: '40px', right: '4px', top: '20px', bottom: '92px' },
    legend: {
      bottom: 0,
      symbol: 'circle',
      textStyle: {
        color: isDark.value ? '#eee' : '#4E5969'
      }
    },
    xAxis: {
      type: 'category',
      data: rounds,
      boundaryGap: false,
      axisLabel: {
        color: '#4E5969',
        formatter (value, idx) {
          if (idx === 0) return `{r|${value}}`
          if (idx === rounds.length - 1) return `{l|${value}}`
          return `${value}`
        },
        width: 150,
        height: 20,
        lineHeight: 20,
        rich: {
          l: { align: 'left' },
          r: { align: 'right' }
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisPointer: {
        show: true,
        lineStyle: {
          color: '#23ADFF',
          width: 2
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisLabel: {
        // formatter (value, idx) {
        //   if (idx === 0) return String(value)
        //   return `${value / 1000}k`
        // }
      },
      min (v) {
        return Math.floor((v.min - 20) / 10) * 10
      },
      splitLine: {
        lineStyle: {
          color: isDark.value ? '#2E2E30' : '#F2F3F5'
        }
      }
    },
    // tooltip: {
    //   trigger: 'axis',
    //   formatter(params) {
    //     const [firstElement] = params as ToolTipFormatterParams[];
    //     return `<div>
    //       <p class="tooltip-title">${firstElement.axisValueLabel}</p>
    //       ${tooltipItemsHtmlString(params as ToolTipFormatterParams[])}
    //     </div>`;
    //   },
    //   className: 'echarts-tooltip-diy',
    // },
    // graphic: {
    //   elements: [
    //     {
    //       type: 'text',
    //       left: '2.6%',
    //       bottom: '18',
    //       style: {
    //         text: '12.10',
    //         textAlign: 'center',
    //         fill: '#4E5969',
    //         fontSize: 12
    //       }
    //     },
    //     {
    //       type: 'text',
    //       right: '0',
    //       bottom: '18',
    //       style: {
    //         text: '12.17',
    //         textAlign: 'center',
    //         fill: '#4E5969',
    //         fontSize: 12
    //       }
    //     }
    //   ]
    // },
    // color: isDark.value ? [] : []
    // x: indexes.map()

    series: indexes.map(i => generateSeries(i.info.name, i.data))
  }
  return option
}
