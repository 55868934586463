<style lang="scss" scoped>
</style>
<template>
  <el-row :gutter="16">
    <el-col :span="12">
      <el-card header="等待计算期次">
        <el-table :data="waiting" style="width: 100%" v-loading="waitingLoading" stripe empty-text="没有数据">
          <el-table-column label="期次"  width="250">
            <round slot-scope="scope" :type="scope.row.type" :round="scope.row.round" />
          </el-table-column>
          <el-table-column label="操作">
            <div slot-scope="scope">
              <el-button  type="text" size="medium" icon="el-icon-edit" @click="clickCalculate(scope.row)">计算</el-button>
            </div>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card header="已计算期次">
        <el-table :data="list" style="width: 100%" v-loading="loading" stripe empty-text="没有数据">
          <el-table-column label="期次"  width="250">
            <round slot-scope="scope" :type="scope.row.type" :round="scope.row.round" />
          </el-table-column>
          <el-table-column label="计算日期" width="190">
            <date-time slot-scope="scope" :time="scope.row.calculateAt" :show-time="true"/>
          </el-table-column>
          <el-table-column label="操作">
            <div slot-scope="scope">
              <el-button  type="text" size="medium" icon="el-icon-refresh-left" @click="resetIndex(scope.row)">撤销计算</el-button>
            </div>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="changeCurrent"
          @size-change="changeSize"
          :current-page.sync="page.current"
          :page-sizes="[20, 40, 60, 100]"
          :page-size="page.size"
          layout="sizes, prev, pager, next, total"
          :total="page.total">
        </el-pagination>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from 'vuex'
import listPageQuery from '../../mixins/list_page_query'

export default {
  data () {
    return {
      url: '/rounds?calculated=1',
      waiting: [],
      waitingLoading: true
    }
  },
  mixins: [listPageQuery],
  computed: {
    ...mapState('indexSystem', ['system']),
    ...mapState('account', { account: 'user' })
  },
  updated () {
    console.log('updated')
  },
  methods: {
    clickCalculate (round) {
      console.log(round)
      this.$confirm(round.calculated ? '确定要重新计算吗' : '确定要计算吗', '请确认')
        .then(res => {
          console.log(res)
          this.$request.post('/index/calculate', { roundId: round.id })
            .then(res => {
              this.$message({ type: 'success', message: '计算成功' })
              this.fetchAll()
            })
        })
    },
    resetIndex (round) {
      this.$confirm('确定要撤销该期次的计算吗？', '请确认')
        .then(res => {
          console.log(res)
          this.$request.post('/index/calculate/reset', { roundId: round.id })
            .then(res => {
              this.$message({ type: 'success', message: '撤销成功' })
              this.fetchAll()
            })
        })
    },
    fetchAll () {
      this.$request('/rounds', { params: { calculated: 0 } })
        .then(res => {
          this.waiting = res.list
        })
        .finally(() => { this.waitingLoading = false })
      this.fetch()
    }
  },
  created () {
    this.fetchAll()
  },
  mounted () {
    // const code = this.$store.state.indexSystem.system[0].code
    // this.value = code
    // this.fetch(code)
  }
}
</script>
