<style lang="scss" scoped>

</style>
<template>
  <el-card header="指标体系">
    <el-row type="flex" :gutter="20">
      <el-menu class="small-nav" @select="fetch" :default-active="defaultMenu">
        <el-menu-item :index="s.code" v-for="s in system" :key="s.code">{{s.code}} {{s.name}}</el-menu-item>
      </el-menu>
      <el-col>
        <el-table
          v-if="tables.length > 0 "
          border
          style="width: 100%"
          size="small"
          :data="tables"
          v-loading="loading"
          stripe
          empty-text="没有数据"
          :span-method="calcSpan"
          >
          <el-table-column v-for="n in tables[0].length" :key="n" :label="n + '级指标'">
            <el-table-column label="指标">
              <div slot-scope="scope">
                {{scope.row[n - 1].code}} {{scope.row[n - 1].name}}<span v-if="scope.row[n-1].measure"> | {{scope.row[n-1].measure}}</span><span v-if="scope.row[n-1].input">*</span>
                <div><el-tag size="mini">{{scope.row[n-1].id}}</el-tag></div>
              </div>
            </el-table-column>
            <el-table-column label="权重" width="80px"><span slot-scope="scope">{{scope.row[n-1].weight}}</span></el-table-column>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      defaultMenu: '',
      loading: true,
      tables: []
    }
  },
  computed: {
    // ...mapState('indexSystem', ['system']),
    ...mapState('account', { account: 'user' }),
    system () {
      return this.$store.state.indexSystem.system.filter(i => !i.total)
    }
  },
  methods: {
    calcSpan (info) {
      const { row, columnIndex } = info
      const index = Math.floor(columnIndex / 2)
      return [row[index].rowspan, 1]
    },
    fetch (code) {
      this.loading = true
      this.$request(`/index/system/${code}`)
        .then(res => {
          this.tables = res
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    const code = this.system[0].code
    this.defaultMenu = code
    this.fetch(code)
  }
}
</script>
