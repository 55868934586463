<template>
  <el-card header="指标体系">
    <div slot="header">
      <el-form inline size="small"  @submit.native.prevent="getData">
        <el-form-item label="期次">
          <season-picker v-model="params.round" @change="getData()" format="yyyy年q季度" value-format="yyyy-MM-dd"/>
        </el-form-item>
      </el-form>
    </div>
    <el-row type="flex" :gutter="20">
      <el-menu class="small-nav" @select="getData" default-active="A">
        <el-menu-item :index="s.code" v-for="s in system" :key="s.code">{{s.code}} {{s.name}}</el-menu-item>
      </el-menu>
      <el-col>
        <el-button @click="exportExcel" style="margin-bottom: 10px;">导出</el-button>
        <el-table
          v-if="data.length > 0 "
          border
          style="width: 100%"
          size="small"
          :data="data"
          v-loading="loading"
          stripe
          empty-text="没有数据"
          :span-method="calcSpan"
        >
          <el-table-column v-for="n in data[0].length" :key="n" :label="(data[0].length - n + 1) + '级指标'">
            <el-table-column label="指标">
              <div slot-scope="scope">{{scope.row[n - 1].code}} {{scope.row[n - 1].name}}</div></el-table-column>
            <el-table-column label="指数" width="100px"><span slot-scope="scope">{{scope.row[n-1].value}}</span></el-table-column>
            <el-table-column label="权重" width="60px"><span slot-scope="scope">{{scope.row[n-1].weight}}</span></el-table-column>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import SeasonPicker from '../../components/SeasonPicker.vue'
import { mapState } from 'vuex'

export default {
  name: 'Data.vue',
  components: {
    SeasonPicker
  },
  data () {
    return {
      params: {},
      defaultMenu: 'A',
      loading: true,
      data: []
    }
  },
  computed: {
    // ...mapState('indexSystem', ['system']),
    ...mapState('account', { account: 'user' }),
    system () {
      return this.$store.state.indexSystem.system.filter(i => !i.total)
    }
  },
  methods: {
    calcSpan (info) {
      const { row, columnIndex } = info
      const index = Math.floor(columnIndex / 3)
      return [row[index].rowspan, 1]
    },
    getData (index) {
      if (!this.params.round) {
        this.$message({ message: '请选择期次', type: 'warning' })
        return
      }
      const gp = index || 'A'
      this.defaultMenu = gp
      this.$request('/index/data/round_gp', { params: { round: this.params.round, gp } })
        .then(res => {
          this.data = res
          this.loading = false
        })
        .catch(() => { this.loading = false })
    },
    exportExcel () {
      if (!this.params.round) {
        this.$message({ message: '请选择期次', type: 'warning' })
        return
      }
      const gp = this.defaultMenu
      window.open(`/api/index/data/round_gp/excel?round=${this.params.round}&gp=${gp}`, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
