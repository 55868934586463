<template>
  <div>
    <el-card class="about">
      <el-form slot="header" inline size="small"  @submit.native.prevent="subQuery">
        <el-form-item label="状态">
          <el-select placeholder="请选择" v-model="params.status" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option :value="1" label="正常"></el-option>
            <el-option :value="-1" label="已禁用"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称" >
          <el-input placeholder="企业名称" v-model="params.name" clearable></el-input>
        </el-form-item>
        <el-form-item><el-button type="primary" native-type="submit">查询</el-button></el-form-item>
        <!-- <el-form-item><el-button type="default"  @click="exp">导出人员银行卡信息</el-button></el-form-item> -->
      </el-form>
      <el-table
        :data="list"
        style="width: 100%">
        <el-table-column label="企业">
          <el-link slot-scope="scope" @click="$showSample(scope.row.id)"><i class="el-icon-link" style="margin-right: 3px; color: #888;"></i>{{scope.row.name}}</el-link>
        </el-table-column>
        <el-table-column label="用户" width="300">
          <div slot-scope="scope">
            <div v-for="item in scope.row.users" :key="item.id">
              <span>{{item.name}}</span>
              <el-divider direction="vertical"/>
              <span>{{item.phone}}</span>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="状态" width="70">
          <template slot-scope="scope">
            <sample-status type="label" :status="scope.row.status" />
          </template>
        </el-table-column>
        <el-table-column label="创建日期" width="150">
          <date-time slot-scope="scope" :time="scope.row.createAt" show-time />
        </el-table-column>
        <el-table-column label="操作" width="180">
          <div slot-scope="scope">
            <el-button type="text" size="medium" icon="el-icon-link" @click="$showSample(scope.row.id)">查看</el-button>
            <el-button type="text" size="medium" icon="el-icon-circle-close" v-if="scope.row.status === 1" @click="updateStatus(scope.row.id, -1)">禁用</el-button>
            <el-button type="text" size="medium" icon="el-icon-circle-close" v-else @click="updateStatus(scope.row.id, 1)">启用</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Samples',
  computed: {
    ...mapState('account', { account: 'user' }),
    ...mapState('samples', ['list', 'error', 'loading', 'page', 'query', 'params'])
  },
  methods: {
    ...mapActions('samples', ['reGet', 'refresh', 'search', 'subQuery', 'changeCurrent', 'changeSize']),
    updateStatus (id, status) {
      this.$confirm(status === 1 ? '确定重新启用该企业填报吗？' : '确定禁用该企业填报吗？', '请确认')
        .then(res => {
          this.$request.put('/samples/' + id, { status })
            .then(res => {
              this.$message('操作成功')
              this.refresh()
            })
        })
    },
    exp () {
      window.location.href = '/api/samples/export'
    }
  },
  mounted () {
    this.reGet()
  }
}
</script>

<style scoped>

</style>
