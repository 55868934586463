<template>
  <div>
    <el-card>
      <div slot="header">
        <el-form inline size="small" v-model="params"  @submit.native.prevent="subQuery">
          <el-form-item label="年度">
            <el-select v-model="params.year" placeholder="请选择" style="width: 120px">
              <el-option
                v-for="item in years"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业名称">
            <el-input placeholder="企业名称" v-model="params.name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit">查询</el-button>
            <el-button type="primary" @click="exp">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list" style="width: 100%" v-loading="loading" stripe empty-text="没有数据">
        <el-table-column fixed label="企业"  width="240">
          <el-link slot-scope="scope" @click="$showSample(scope.row.id)"><i class="el-icon-link" style="margin-right: 3px; color: #888;"></i>{{scope.row.name}}</el-link>
        </el-table-column>
         <el-table-column :label="t.name" v-for="t in tableRounds" :key="t.id">
           <el-table-column  v-for="r in t.rounds" :key="r.id" width="120">
             <div slot="header">
                <round :type="r.type" :round="r.round" />
             </div>
             <div slot-scope="scope">
                <el-tooltip v-if="scope.row.report && scope.row.report[t.id] && scope.row.report[t.id][r.id]" content="点击查看" placement="top-start">
                 <el-tag effect="dark" type="success" size="mini" style="cursor: pointer;" @click="$showReport(scope.row.report[t.id][r.id].reportId)">已填报</el-tag>
                </el-tooltip>
                <el-tag v-else effect="dark" type="danger" size="mini">未填报</el-tag>
             </div>
           </el-table-column>
         </el-table-column>
        <el-table-column label="当年度全填报"  width="70">
          <div slot-scope="scope">
            <el-tag v-if="scope.row.report && scope.row.report.allYear" effect="dark" type="success" size="mini" style="cursor: pointer;" @click="$showReport(scope.row.report[t.id][r.id].reportId)">是</el-tag>
            <el-tag v-else effect="dark" type="danger" size="mini">否</el-tag>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import listPageQuery from '../../mixins/list_page_query'

const year = new Date().getFullYear()
const years = []
for (let i = 2021; i <= year; i++) {
  years.push(i)
}

export default {
  mixins: [listPageQuery],
  // data () {
  //   return {
  //     years: [],
  //     url: '/reports/samples',
  //     tableRounds: []
  //   }
  // },
  data () {
    return {
      years,
      url: '/reports/samples',
      tableRounds: [],
      loading: false,
      params: { year },
      list: [],
      error: null,
      query: {},
      page: { total: 0, current: 1, size: 20 }
    }
  },
  computed: {
    ...mapState('account', { account: 'user' }),
    ...mapState(['areas'])
  },
  methods: {
    fetchSuccess (res) {
      const { total, current, list, tableRounds, size } = res
      this.page = { total, current, size }
      this.error = null
      this.loading = false
      this.list = list
      this.tableRounds = tableRounds
    },
    exp () {
      window.location.href = '/api/reports/samples/excel?year=' + this.params.year
    }
  },
  created () {
    // const year = new Date().getFullYear()
    // const years = []
    // for (let i = 2021; i <= year; i++) {
    //   years.push(i)
    // }
    // this.years = years
    // this.params.year = year
  },
  mounted () {
    // this.requestData({ year: new Date().getFullYear() })
    this.subQuery()
  }
}
</script>
