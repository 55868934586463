<template>
  <el-card>
    <div slot="header">
      <el-form  inline size="small" v-model="params"  @submit.native.prevent="subQuery">
        <el-form-item>
          <el-radio-group v-model="params.maoyanStatus" size="small" @change="subQuery">
            <el-radio-button label="">所有企业</el-radio-button>
            <el-radio-button label="1">抓取</el-radio-button>
            <el-radio-button label="-1">不需抓取</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-select v-model="params.maoyan" size="small">
            <el-option value="" :label="`所有企业（${info.all}）`">所有企业（{{info.all}}）</el-option>
            <el-option value="done" :label="`已绑定（${info.done}）`"></el-option>
            <el-option value="undone" :label="`未绑定（${info.undone}）`"></el-option>
            <el-option value="noneed" :label="`不需采集（${info.noneed}）`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-input placeholder="企业名称" v-model="params.name"></el-input>
        </el-form-item>
        <el-form-item><el-button type="primary" native-type="submit">查询</el-button></el-form-item>
      </el-form>
      <div style="margin-top: 8px;">*在系统中的企业，如果参与出品或发行电影、电视，需要到猫眼app专业版，搜索到对应的企业，并保存猫眼app对应ID。</div>
    </div>
    <div>
      <el-table :data="list" style="width: 100%" v-loading="loading" stripe empty-text="没有数据">
        <el-table-column label="企业"  width="240">
          <el-link slot-scope="scope" @click="$showSample(scope.row.id)"><i class="el-icon-link" style="margin-right: 3px; color: #888;"></i>{{scope.row.name}}</el-link>
        </el-table-column>
        <el-table-column label="是否抓取"  width="90">
          <div slot-scope="scope">
            <el-tag v-if="scope.row.maoyanStatus === 1" type="success" size="mini">抓取</el-tag>
            <el-tag v-else-if="scope.row.maoyanStatus === -1" type="warning" size="mini">不需抓取</el-tag>
          </div>
        </el-table-column>
        <el-table-column prop="maoyanId" label="猫眼ID" width="80">
          <div slot-scope="scope">
            <span v-if="scope.row.maoyanId === 0">未设置</span>
            <span v-else-if="scope.row.maoyanId === -1">不需要采集</span>
            <span v-else>{{ scope.row.maoyanId }}</span>
          </div>
        </el-table-column>
        <el-table-column prop="x" label="出品影视数量" width="120"></el-table-column>
        <el-table-column prop="x" label="联合出品数量" width="120"></el-table-column>
        <el-table-column prop="x" label="发行影视数量" width="120"></el-table-column>
        <el-table-column prop="x" label="联合发行数量" width="120"></el-table-column>
        <el-table-column label="状态" width="70">
          <template slot-scope="scope">
            <sample-status type="label" :status="scope.row.status" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <div slot-scope="scope">
            <el-button size="mini" v-if="scope.row.maoyanId > 0" @click="openMaoyan(scope.row.maoyanId)">查看猫眼页面</el-button>
            <el-button size="mini" @click="showMaoyan(scope.row.id, scope.row.maoyanId)">设置猫眼ID</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </div>
    <el-dialog
      title="设置猫眼app对应企业ID"
      :visible.sync="maoyanVisible"
      width="500px">
      <div>
        <el-form ref="form0" :model="my" @submit.native.prevent="saveMaoyan">
          <el-form-item prop="maoyanId" label="猫眼专业版APP对应企业ID" :rules="[{ required: true, message: '请输入猫眼专业版APP对应企业ID'},  { type: 'number', message: '请输入正确的格式'}]">
            <el-input v-model.number="my.maoyanId"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="line-height: 1.5em; font-size: 12px;">
        在猫眼专业版app中，搜索企业名称，找到对应企业的页面，通过分享按钮，复制链接地址，即可获得对应ID。
        <br />
        示例：https://piaofang.maoyan.com/companydetail/4469  该链接中最后的数字即为ID
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="maoyanVisible = false">取 消</el-button>
        <el-button type="" @click="saveMaoyanNoNeed">不需要采集</el-button>
        <el-button type="primary" @click="saveMaoyan">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import listPageQuery from '../../mixins/list_page_query'
export default {
  mixins: [listPageQuery],
  data () {
    return {
      url: '/samples',
      params: {
        maoyan: ''
      },
      info: {
        done: 0,
        noneed: 0,
        undone: 0
      },
      sampleId: 0,
      my: {
        maoyanId: null
      },
      maoyanVisible: false
    }
  },
  methods: {
    fetchCount () {
      this.$request.get('/samples/maoyan/count')
        .then(res => {
          this.info = res
        })
    },
    showMaoyan (id, maoyanId) {
      this.maoyanVisible = true
      this.sampleId = id
      this.my.maoyanId = maoyanId
    },
    saveMaoyanNoNeed () {
      this.$request.put(`/samples/${this.sampleId}`, { maoyanId: -1 })
        .then(() => {
          this.refresh()
          this.fetchCount()
          this.maoyanVisible = false
        })
    },
    saveMaoyan (maoyanId) {
      this.$refs.form0.validate()
        .then(valid => {
          if (!valid) return
          this.$request.put(`/samples/${this.sampleId}`, { maoyanId: this.my.maoyanId })
            .then(() => {
              this.refresh()
              this.fetchCount()
              this.maoyanVisible = false
            })
        })
    },
    openMaoyan (id) {
      if (id === 0 || id === -1) return
      const url = `https://piaofang.maoyan.com/companydetail/${id}`
      window.open(url)
    }
  },
  created () {
    // this.params = {}
  },
  mounted () {
    // this.params.maoyan = 'undone'
    this.requestData({ maoyan: '' })
    this.fetchCount()
  }
}
</script>

<style scoped>

</style>
