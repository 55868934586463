<template>
  <div>
    <el-card>
      <el-form slot="header" inline size="small"  @submit.native.prevent="search(params)">
        <el-form-item label="姓名">
          <el-input placeholder="姓名" v-model="params.name"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input placeholder="电话" v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item><el-button type="default" @click="clickAddUser" v-if="account.role === 'admin' && !account.area">创建用户</el-button></el-form-item>
        <el-form-item><el-button type="primary" native-type="submit">查询</el-button></el-form-item>
      </el-form>
      <el-table :data="list" style="width: 100%" v-loading="loading" stripe empty-text="没有数据">
        <el-table-column prop="name" label="姓名" width="90"></el-table-column>
        <el-table-column prop="phone" label="电话" width="120"></el-table-column>
        <el-table-column prop="loginName" label="用户名" width="120"></el-table-column>
        <el-table-column label="企业">
          <template slot-scope="scope">
            <el-link v-if="scope.row.sample" @click="$showSample(scope.row.sample.id)">{{scope.row.sample.name}}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="70">
          <el-tag size="small" :type="scope.row.status === 1 ? 'info' : 'danger'" slot-scope="scope">{{scope.row.status === 1 ? '正常' : scope.row.status === -1 ? '已禁用' : ''}}</el-tag>
        </el-table-column>
        <el-table-column label="最近登录" width="150">
          <date-time slot-scope="scope" :time="scope.row.lastLoginAt" show-time/>
        </el-table-column>
        <el-table-column label="创建日期" width="150">
          <date-time slot-scope="scope" :time="scope.row.createAt" show-time/>
        </el-table-column>
        <el-table-column label="角色" width="90">
          <div slot-scope="scope">{{scope.row.role === 'admin' ? '管理员' : scope.row.role === 'sample' ? '企业用户' : ''}}</div>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <div slot-scope="scope">
            <el-button  type="text" size="medium" v-if="scope.row.status === 1" @click="updateStatus(scope.row.id, -1)">禁用</el-button>
            <el-button  type="text" size="medium" v-if="scope.row.status === -1" @click="updateStatus(scope.row.id, 1)">启用</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </el-card>
    <el-dialog :visible.sync="dialogVisible" title="创建用户" width="500px">
      <el-form ref="form" :model="form" :rules="rules" hide-required-asterisk label-width="120px" @submit.native.prevent="doAdd">
        <el-form-item label="角色" prop="role">
          <el-select v-model="form.role" placeholder="请选择">
            <el-option label="管理员" value="admin"></el-option>
            <el-option label="企业用户" value="sample"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item :label="form.role === 'sample' ? '企业名称' : '登录名'" prop="loginName">
          <el-input v-model="form.loginName" placeholder="请输入登录名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input placeholder="请输入密码" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit" size="medium" :loading="posting">创建用户</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      dialogVisible: false,
      form: { password: 'zstj@1234' },
      rules: {
        role: [
          { required: true, message: '请选择权限' }
        ],
        loginName: [
          { required: true, message: '不能为空' }
        ],
        phone: [
          { required: true, message: '请输入手机号码' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名' },
          { max: 50, message: '长度不能超过50', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ]
      },
      posting: false
    }
  },
  computed: {
    ...mapState('users', ['list', 'error', 'loading', 'page', 'query', 'params']),
    ...mapState('account', { account: 'user' })
  },
  methods: {
    ...mapActions('users', ['search', 'subQuery', 'refresh', 'changeCurrent', 'changeSize']),
    clickAddUser () {
      // this.$refs.form.resetFields()
      this.form = { password: 'zstj@1234' }
      this.dialogVisible = true
    },
    doAdd () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.posting = true
        this.$request.post('/users', { ...this.form })
          .then(() => {
            this.$message({ type: 'success', message: '添加成功', showClose: true })
            this.posting = false
          })
          .catch(() => {
            this.posting = false
          })
      })
    },
    updateStatus (id, status) {
      this.$confirm(status === 1 ? '确定重新启用该账号吗？' : '确定禁用该账号吗？')
        .then(res => {
          this.$request.put('/users/' + id, { status })
            .then(res => {
              this.$message('操作成功')
              this.refresh()
            })
        })
    }
  },
  mounted () {
    this.search()
  }
}
</script>
