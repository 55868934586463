<template>
<el-card>
  <div slot="header">添加电影或电视剧</div>
  <div>
    <el-steps :active="step" finish-status="success" align-center style="margin-bottom: 20px;">
      <el-step title="输入名称"></el-step>
      <el-step title="完善数据"></el-step>
      <el-step title="完成创建"></el-step>
    </el-steps>
    <div v-show="step === 0" style="display: flex;">
      <div style="flex: 1;">
        <div>通过电影或电视剧名称查询</div>
        <el-form label-width="140px" ref="form0" @submit.native.prevent="subTitle">
          <el-form-item label="电影或电视剧名称" required>
            <el-input v-model="title"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="" style="width: 100%;" native-type="submit" :loading="requesting0">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="width: 20%;min-width: 200px; margin-left: 10px;">
        <el-form ref="form1" @submit.native.prevent="subDoubanId">
          <el-form-item label="直接输入豆瓣ID查询" required>
            <el-input v-model="doubanId"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="" style="width: 100%;" native-type="submit" :loading="requesting1">下一步</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-show="step === 1" style="max-width: 500px; margin: 0 auto;">

    </div>
    <div v-show="step === 2" style="max-width: 500px; margin: 0 auto;">
      <el-form label-width="130px" ref="form2" :model="info" @submit.native.prevent="subInfo">
        <el-form-item label="输入豆瓣ID">
          <el-input v-model="info.doubanId"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex;">
            <el-button type="default" size="" @click="() => this.step = this.step - 1">上一步</el-button>
            <el-button type="primary" size="" native-type="submit" :loading="requesting2" style="flex: 1;">提 交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</el-card>
</template>

<script>
export default {
  name: 'Create',
  data () {
    return {
      step: 0,
      title: '',
      doubanId: '',
      requesting0: false,
      requesting1: false,
      requesting2: false,
      info: {}
    }
  },
  methods: {
    subTitle () {
      if (!this.title) {
        this.$message({ type: 'warning', message: '请输入名称' })
        return
      }
      this.requesting0 = true
      this.$request.post('/movies/pre_create/title', { title: this.title })
        .then(res => {
          console.log(res)
        })
        .finally(() => {
          this.requesting0 = false
        })
    },
    subDoubanId (e) {
      if (!this.doubanId) {
        this.$message({ type: 'warning', message: '请输入豆瓣ID' })
        return
      }
      this.$request.post('/movies/pre_create/id', { doubanID: this.doubanId })
        .then(res => {
          this.step = 2
          this.info = res
        })
        .finally(() => {
          this.requesting1 = false
        })
    },
    subInfo () {
      console.log('sub info')
    }
  }
}
</script>

<style scoped>

</style>
