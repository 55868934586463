<template>
<el-row :gutter="20">
  <el-col :span="4">
    <el-card header="记录">
      <div v-for="record in records" :key="record.id">
        <div class="flex record">
          <div class="flex-1">
            <div  @click="clickRecord(record.id)">
              <div>
                <span>范围：</span><date-time :time="record.start" /> ~ <date-time :time="record.end" />
              </div>
              <div>
                <span>状态：</span>
                <el-tag type="primary" size="mini" v-if="record.status === 1">完成</el-tag>
                <el-tag type="warning" size="mini" v-else-if="record.status === 0">中断</el-tag>
                <span style="margin-left: 8px;">数据：</span>{{record.counts}}<span>部</span>
              </div>
              <div>
                <span>开启：</span><date-time show-time :time="record.startAt" />
                <span style="margin-left: 8px;">结束：</span><date-time show-time :time="record.endAt" />
              </div>
            </div>
          </div>
          <div class="act">
            <i class="el-icon-success" v-if="recordId === record.id"/>
            <el-button type="text" size="mini" @click="exp(record.id)">导出</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </el-col>
  <el-col :span="20">
    <el-form class="query" inline size="small" v-model="params"  @submit.native.prevent="subQuery" >
      <el-form-item label="">
        <el-select v-model="params.type" clearable placeholder="所有类型" style="width: 100px">
          <el-option value="电影"></el-option>
          <el-option value="电视剧"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input placeholder="影视剧名称" v-model="params.name" clearable style="width: 150px"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="params.weiboFixed" clearable placeholder="微博选择" style="width: 120px">
          <el-option :value="1" label="已选择"></el-option>
          <el-option :value="-1" label="未选择"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="params.youtubeFixed" clearable placeholder="youtube选择" style="width: 120px">
          <el-option :value="1" label="已选择"></el-option>
          <el-option :value="-1" label="未选择"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item><el-button type="primary" native-type="submit">查询</el-button></el-form-item>
    </el-form>
    <div class="data">
      <div class="data-item" v-for="item in list" :key="item.id">
        <div class="item">
          <div class="mv">
            <movie :movie="item.movie" :is-card="false"/>
          </div>
          <div class="dt">
            <div class="site">
              <span class="name">猫眼： </span>
              <div v-if="item.maoyan" class="info">
                <span class="lb">评分</span><span class="num">{{item.maoyan.rating}}</span>
                <span class="lb">票房</span><span class="num">{{item.maoyan.piaofang}}</span>
              </div>
            </div>

            <div class="site">
              <span class="name">IMDB： </span>
              <div v-if="item.imdb" class="info">
                <div>
                  <span class="lb">评分</span><span class="num">{{item.imdb.rating}}</span>
                  <span class="lb">评分人数</span><span class="num">{{item.imdb.ratingUser}}</span>
                </div>
                <div>
                  <span class="lb">user reviews</span><span class="num">{{item.imdb.userReviews}}</span>
                  <span class="lb">critic reviews</span><span class="num">{{item.imdb.criticReviews}}</span>
                </div>
              </div>
            </div>
            <div class="site">
              <span class="name">BoxOffice： </span>
              <div v-if="item.boxoffice" class="info">
                <span class="lb">world</span><span class="num">{{item.boxoffice.world}}</span>
                <span class="lb">china</span><span class="num">{{item.boxoffice.china}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">优酷： </span>
              <div v-if="item.youku" class="info">
                <span class="lb">热度</span><span class="num">{{item.youku.heat}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">腾讯： </span>
              <div v-if="item.tengxun" class="info">
                <span class="lb">热度</span><span class="num">{{item.tengxun.heat}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">芒果： </span>
              <div v-if="item.mangguo" class="info">
                <span class="lb">点赞</span><span class="num">{{item.mangguo}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">抖音： </span>
              <div v-if="item.douyin" class="info">
                <span class="lb">指数</span><span class="num">{{item.douyin}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">百度： </span>
              <div v-if="item.baidu" class="info">
                <span class="lb">指数</span><span class="num">{{item.baidu.searchIndex}}</span>
                <span class="lb">资讯</span><span class="num">{{item.baidu.feedIndex}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">百科： </span>
              <div v-if="item.baike" class="info">
                <span class="lb">获奖数</span><span class="num">{{item.baike.pricesCount}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">爱奇艺：</span>
              <div class="info">
                <span class="lb">数据（条）</span><span class="num">{{item.iqiyi.count}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">豆瓣： </span>
              <div v-if="item.douban" class="info">
                <span class="lb">评分</span><span class="num">{{item.douban.rating}}</span>
                <span class="lb">评论</span><span class="num">{{item.douban.commentsCount}}</span>
              </div>
            </div>
            <div class="site">
              <span class="name">微博：</span>
              <div class="info">
                <span class="lb">话题</span><span class="num">{{item.weibo.count}}</span>
                <span v-if="item.weiboFixed" class="success"><i class="el-icon-success"></i>已选择</span>
                <span v-else class="error"><i class="el-icon-error"></i>未选择</span>
              </div>
            </div>
            <div class="site">
              <span class="name">youtube：</span>
              <div class="info">
                <span class="lb">视频</span><span class="num">{{item.youtube.count}}</span>
                <span v-if="item.youtubeFixed" class="success"><i class="el-icon-success"></i>已选择</span>
                <span v-else class="error"><i class="el-icon-error"></i>未选择</span>
              </div>
            </div>
            <div class="act">
<!--              <el-button type="text" size="mini" style="color:#333;" icon="el-icon-edit">处理</el-button>-->
              <el-button type="text" size="mini" style="color:#333;" icon="el-icon-link" @click="viewItem(item.id)">查看</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      @current-change="changeCurrent"
      @size-change="changeSize"
      :current-page.sync="page.current"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="page.size"
      layout="sizes, prev, pager, next, total"
      :total="page.total">
    </el-pagination>
  </el-col>
  <el-drawer
    title="查看具体信息"
    :visible.sync="showDialog"
    destroy-on-close
    :before-close="resetChecks"
    direction="rtl"
    size="50%"
    >
    <div style="padding: 5px 20px;">
      <el-tabs tab-position="top" v-if="item">
        <el-tab-pane :label="`微博(${item.weibo ? item.weibo.list.length : '-'})`" >
          <div class="note">
            <span class="txt">从以下选择5条数据，作为计算用。</span>
            <span class="txt" v-if="checkWeibo.length > 0">已选择{{checkWeibo.length}}条</span>
            <el-button type="primary" size="small" @click="saveFixed('weibo')">保 存</el-button>
          </div>
          <div v-if="item.weibo">
            <el-checkbox-group v-model="checkWeibo" :max="5">
            <div v-for="(item, index) in item.weibo.list" :key="item.scheme" class="weibo">
              <div class="ck">
                <el-checkbox :label="index">选择</el-checkbox>
              </div>
              <div class="info">
                <a :href="item.scheme" target="_blank" class="t">{{item.title_sub}}</a>
                <div class="d1">{{item.desc1}}</div>
                <div class="d2">{{item.desc2}}</div>
              </div>
            </div>
            </el-checkbox-group>
          </div>
          <div v-else>没有抓取</div>
        </el-tab-pane>
        <el-tab-pane :label="`微博选中(${item.weiboFixed ? item.weiboFixed.length : '-'})`" >
          <div v-if="item.weiboFixed">
            <div v-for="(item) in item.weiboFixed" :key="item.scheme" class="weibo">
              <div class="info">
                <a :href="item.scheme" target="_blank" class="t">{{item.title_sub}}</a>
                <div class="d1">{{item.desc1}}</div>
                <div class="d2">{{item.desc2}}</div>
              </div>
            </div>
            <div style="margin-top: 8px; font-weight: 700;">共计：{{item.weiboFixedCommentsCount}}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="`youtube(${item.youtube ? item.youtube.list.length : '-'})`">
          <div class="note">
            <span class="txt">从以下选择5条数据，作为计算用。</span>
            <span class="txt" v-if="checkYoutube.length > 0">已选择{{checkYoutube.length}}条</span>
            <el-button type="primary" size="small" @click="saveFixed('youtube')">保 存</el-button>
          </div>
          <div v-if="item.youtube">
            <el-checkbox-group v-model="checkYoutube" :max="5">
              <div v-for="(item, index) in item.youtube.list" :key="item.url" class="weibo">
                <div class="ck">
                  <el-checkbox :label="index">选择</el-checkbox>
                </div>
                <div class="info">
                  <a :href="item.url" target="_blank" class="t">{{item.title}}</a>
                  <div class="d1">播放次数：{{item.playTimes}}</div>
                </div>
              </div>
            </el-checkbox-group>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="`youtube选中(${item.youtubeFixed ? item.youtubeFixed.length : '-'})`" >
          <div v-if="item.youtubeFixed">
            <div v-for="(item) in item.youtubeFixed" :key="item.url" class="weibo">
              <div class="info">
                <a :href="item.url" target="_blank" class="t">{{item.title}}</a>
                <div class="d1">播放次数：{{item.playTimes}}</div>
              </div>
            </div>
            <div style="margin-top: 8px; font-weight: 700;">共计：{{item.youtubeFixedPlayTimes}}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="`豆瓣(${item.douban ? item.douban.comments.length : '-'})`">
          <div v-if="item.douban">
            <div v-for="(item, index) in item.douban.comments" :key="item.url" class="weibo">
              <div class="info">
                <a :href="item.url" class="t">（{{index + 1}}）查看 ({{item.date}})</a>
                <p class="d1">{{item.comment}}</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="`百科(${item.baike ? item.baike.prices.length : '-'})`">
          <div v-if="item.baike && item.baike.prices.length > 0">
            <table class="price-table" v-if="item.baike.headers.length > 0">
              <tr>
                <th v-for="h in item.baike.headers" :key="h">{{h}}</th>
              </tr>
              <tr v-for="(price, priceIndex) in item.baike.prices" :key="priceIndex">
                <td v-for="(p, key) in item.baike.prices[0]" :key="key">{{price[key]}}</td>
              </tr>
            </table>
            <div v-else>
              <div v-for="(item, index) in item.baike.prices" :key="index" class="weibo">
                <div class="info">
                  <div>{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="爱奇艺">
          <div v-if="item.iqiyi">
            <div v-if="item.iqiyi.heat">
              {{ item.iqiyi.heat }}
            </div>
            <div v-if="item.iqiyi.heats">
              <div v-for="item in item.iqiyi.heats" :key="item.date" class="weibo">
                <div class="info">
                  <span>{{item.date}}</span>：<span>{{item.heat}}</span>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</el-row>
</template>

<script>
import listPageQuery from '../../mixins/list_page_query'
import movie from '../../components/Movie'
export default {
  name: 'Data',
  components: { movie },
  mixins: [listPageQuery],
  data () {
    return {
      recordId: 0,
      records: [],
      item: null,
      showDialog: false,
      checkWeibo: [],
      checkYoutube: []
    }
  },
  methods: {
    url () {
      return `/crawler/records/${this.recordId}/data`
    },
    viewItem (id) {
      this.$request.get(`/crawler/records/${this.recordId}/data/${id}`).then(res => {
        this.item = res
        this.showDialog = true
      })
    },
    clickRecord (id) {
      this.recordId = id
      this.fetch()
    },
    saveFixed (type) {
      const keys = type === 'weibo' ? this.checkWeibo : this.checkYoutube
      // if (keys.length !== 0) this.$message({ type: 'warning', message: ''})
      // console.log(keys)
      this.$request.post(`/crawler/records/${this.recordId}/data/${this.item.id}/fix`, { type, keys })
        .then(res => {
          this.$message({ type: 'success', message: '保存成功' })
          this.viewItem(this.item.id)
          this.refresh()
        })
    },
    resetChecks (done) {
      this.checkYoutube = []
      this.checkWeibo = []
      done()
    },
    exp (id) {
      window.location.href = `/api/crawler/records/${id}/data/export`
    }
  },
  mounted () {
    // this.fetch()
    // this.getRecords()
    this.$request.get('/crawler/records').then(res => {
      this.records = res
      if (res.length > 0) {
        this.recordId = res[0].id
      }
      this.fetch()
    })
  }
}
</script>

<style scoped lang="scss">
.query {
  .el-form-item--small.el-form-item { margin-bottom: 4px; }
}
.flex {
  display: flex;
  .flex-1 { flex: 1; }
}
.record {
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  line-height: 1.7em;
  font-size: 13px;
  //margin-bottom: 10px;
}

.data {
  display: flex; flex-wrap: wrap; margin-left: -10px;
}
.data-item {
  margin-bottom: 14px;
  flex-basis: 50%; flex-grow: 0; flex-shrink: 1; min-width: 0; box-sizing: border-box; padding-left: 10px;
  .item { display: flex; background-color: #fff; padding: 12px; }
  .mv {
    margin-right: 10px;
  }
  .dt { flex: 1;  }
  .site {
    font-size: 13px;
    color: #777;
    margin-bottom: 5px;
    margin-right: 10px;
    &.block { display: block; }
    .name { color: #333; font-weight: 600; }
    .info { display: inline-block;  vertical-align: top; }
    .lb {  margin-right: 3px; }
    .num { color: #333; margin-right: 8px;}
    .success, .error { font-size: 12px; margin-left: 3px; }
    .success { color: #67C23A; }
    .error { color: #E6A23C; }
  }
  .act {
    border-top: 1px solid #eeeeee; margin-top: 4px; text-align: right;
  }
}
.note {
  .txt { color: #666; font-size: 13px; margin-right: 8px; }
}
.weibo {
  display: flex; font-size: 13px; justify-content: center; align-items: center;
  line-height: 1.4em; padding: 8px 0; border-bottom: 1px solid #eee;
  .ck {
    margin-right: 13px;
  }
  .info { flex: 1; }
  .t {
    font-size: 14px; font-weight: 500; color: #333; cursor: pointer;
    &:hover { text-decoration: underline; }
  }
  .d1, .d2 { color: #666; font-size: 13px;  }
  .d2 {}
}
.price-table {
  border-collapse: collapse;
  th { font-weight: bold; }
  th, td {
    border: 1px solid #eee; padding: 8px 12px;
  }
}
</style>
