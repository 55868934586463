<template>
  <div>
    <el-card>
      <el-table :data="list" style="width: 100%" v-loading="loading" stripe empty-text="没有数据">
        <el-table-column label="期次"  width="250">
          <round slot-scope="scope" :type="scope.row.type" :round="scope.row.round" />
        </el-table-column>
        <el-table-column label="状态" width="140">
          <div slot-scope="scope">{{scope.row.status === 1 ? '开放填报' : '已截止'}}</div>
        </el-table-column>
        <el-table-column label="最后填报日期" width="190">
          <date-time slot-scope="scope" :time="scope.row.endAt"/>
        </el-table-column>
        <el-table-column label="操作">
          <div slot-scope="scope">
            <el-button  type="text" size="medium" icon="el-icon-edit" @click="clickRound(scope.row.id)">修改截止日期</el-button>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </el-card>
    <el-dialog
      title="请选择截止日期"
      :visible.sync="dialogVisible"
      width="400px">
      <el-date-picker
        v-model="newEnd"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择日期">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveNewEnd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return { dialogVisible: false, roundId: 0, newEnd: null }
  },
  computed: {
    ...mapState('rounds', ['list', 'error', 'loading', 'page', 'query', 'params']),
    ...mapState('account', { account: 'user' })
  },
  methods: {
    ...mapActions('rounds', ['search', 'subQuery', 'changeCurrent', 'changeSize']),
    clickAgg (roundId) {
      this.$confirm('确定要进行数据汇总吗')
        .then(() => this.$request.post('/aggregation/aggregate', { roundId }))
        .then(() => this.$message({ type: 'success', message: '操作成功！' }))
    },
    clickRound (roundId) {
      this.dialogVisible = true
      this.roundId = roundId
    },
    saveNewEnd () {
      if (!this.newEnd) return
      console.log(this.roundId, this.newEnd)
      this.$request.put('/rounds/' + this.roundId, { endAt: this.newEnd })
        .then(() => this.$message({ type: 'success', message: '操作成功！' }))
        .then(() => {
          this.search()
          this.dialogVisible = false
        })
    }
  },
  mounted () {
    this.search()
  }
}
</script>
