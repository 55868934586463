<style lang="scss" scoped>

</style>
<template>
  <el-card header="指标填报">
    <el-row type="flex" :gutter="20">
      <el-menu class="small-nav" @select="(index) => this.fetchInput(index, this.round)" :default-active="defaultMenu">
        <el-menu-item :index="s.code" v-for="s in system" :key="s.code">{{s.code}} {{s.name}}</el-menu-item>
      </el-menu>
      <el-col v-if="loading === false">
        <div style="">
          <el-button v-for="r in rounds" :key="r.id" :type="round ===r.round ? 'primary' : 'default'" @click="fetchInput(defaultMenu, r.round)"><round :round="r.round" :type="r.type"/></el-button>
        </div>
        <el-divider  />
        <div v-if="defaultMenu !== 'A'" style="margin-bottom: 10px">
          <el-button type="primary" round size="small" @click="doPreReport">预计算</el-button>
        </div>
        <el-form v-if="rounds.length > 0" ref="form" :model="form" :inline-message="true" @submit.native.prevent="doReport" size="mini" label-width="0px" label-position="left" hide-required-asterisk>
          <el-table
            border
            style="width: 100%"
            size="small"
            :data="inputs"
            v-loading="loading"
            stripe
            empty-text="没有数据"
            >
            <el-table-column label="指标" width="310px">
              <span slot-scope="scope">{{scope.row.index.id}} | {{scope.row.index.code}} {{scope.row.index.name}}</span>
            </el-table-column>
            <el-table-column label="填报" width="200px">
              <el-input-number slot-scope="scope" size="small" v-model="form[scope.row.index.id]" :controls="false" :precision="4" :min="0"></el-input-number>
            </el-table-column>
            <el-table-column label="单位">
              <span slot-scope="scope">{{scope.row.index.input === 2 ? '指数*' : scope.row.index.measure}}</span>
            </el-table-column>
          </el-table>
          <div style="margin-top: 30px; text-align:center">
            <el-button size="medium" :loading="posting" native-type="submit" type="primary" style="width: 110px; ">提 交</el-button>
          </div>
        </el-form>
        <div v-else>没有未计算的期次</div>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      defaultMenu: '',
      loading: true,
      round: '',
      inputs: null,
      form: {},
      posting: false,
      rounds: []
    }
  },
  computed: {
    ...mapState('account', { account: 'user' }),
    // ...mapState('rounds', ['opening']),
    system () {
      return this.$store.state.indexSystem.system.filter(i => !i.total && i.inputs)
    }
  },
  methods: {
    ...mapActions('rounds', ['fetchOpening']),
    fetchInput (gp, round) {
      if (!round) {
        this.defaultMenu = gp
        return Promise.resolve()
      }
      return this.$request('/index/inputs/' + round, { params: { gp } })
        .then(res => {
          this.round = round
          this.inputs = res
          const x = {}
          for (const i in res) {
            const item = res[i]
            x[item.index.id] = item.data === null ? undefined : item.data
          }
          this.defaultMenu = gp
          this.form = x
        })
    },
    doPreReport () {
      this.$request.post('/index/inputs/' + this.round + '/pre', { gp: this.defaultMenu })
        .then(res => {
          this.$message({ type: 'success', message: '预计算成功' })
          this.fetchInput(this.defaultMenu, this.round)
        })
    },
    doReport () {
      this.$refs.form.validate((valid) => {
        if (!valid) return this.$message({ type: 'warning', message: '有不符要求的填报项，请查看标红项' })
        this.posting = true
        this.$request.post(`/index/inputs/${this.round}`, { gp: this.defaultMenu, data: this.form })
          .then(res => {
            this.$message({ type: 'success', message: '上报成功' })
          })
          .finally(() => {
            this.posting = false
          })
      })
    }
  },
  created () {
    const gp = this.system[0].code
    this.defaultMenu = gp
    this.$request('/rounds/not_calculated')
      .then(rounds => {
        this.rounds = rounds
        if (rounds.length === 0) {
          return Promise.resolve()
        }
        return this.fetchInput(gp, rounds[0].round)
      })
      .catch(err => {
        this.error = err
        this.rounds = []
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
