<style lang="scss" scoped>

</style>
<template>
  <el-card>
    <div slot="header">
      <el-form inline size="small"  @submit.native.prevent="subQuery">
        <el-form-item label="期次">
          <season-picker v-model="params.round" format="yyyy年q季度" value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" native-type="submit">查询</el-button>
           <el-button @click="exportExcel" style="margin-left: 10px;">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>

      <el-table
        border
        style="width: 100%"
        :data="tb"
      >
        <el-table-column label="指标" width="85px" prop="name" fixed>
        </el-table-column>
        <el-table-column v-for="sys in system[2]" :label="sys.name" :key="sys.id">
          <el-table-column v-for="yw in yewu" :label="yw.name" :key="yw.code">
            <el-table-column v-for="b in bjq" :label="b.name" :key="b.code">
              <span slot-scope="scope">{{counts[`${sys.id}_${yw.code}_${b.code}`] ? counts[`${sys.id}_${yw.code}_${b.code}`][scope.row.code] : ''}}</span>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-table
        border
        style="width: 100%; margin-top: 20px;"
        :data="[...system[1], ...system[0]]"
        >
        <el-table-column label="指标" width="160px">
          <span slot-scope="scope">{{scope.row.code}} {{scope.row.name}}</span>
        </el-table-column>

        <el-table-column v-for="yw in yewu" :label="yw.name" :key="yw.code">
          <el-table-column v-for="b in bjq" :label="b.name" :key="b.code">
            <span slot-scope="scope">{{data[`${scope.row.id}_${yw.code}_${b.code}`]}}</span>
          </el-table-column>
        </el-table-column>
      </el-table>

      <el-table
        border
        style=" margin-top: 20px; width: 50%;"
        :data="[...system[1], ...system[0]]"
      >
        <el-table-column label="指标" width="160px">
          <span slot-scope="scope">{{scope.row.code}} {{scope.row.name}}</span>
        </el-table-column>
        <el-table-column v-for="yw in yewu" :label="yw.name" :key="yw.code" >
            <span slot-scope="scope">{{data[`${scope.row.id}_${yw.code}_`]}}</span>
        </el-table-column>
        <el-table-column label="调查内容维度" >
          <span slot-scope="scope">{{data[`${scope.row.id}__`]}}</span>
        </el-table-column>
      </el-table>

      <el-table
        border
        style=" margin-top: 20px; width: 50%;"
        :data="[...yewu, { code: '', name: '比较期维度'}]"
      >
        <el-table-column label="业务" prop="name" width="160px"></el-table-column>
        <el-table-column v-for="b in bjq" :label="b.name" :key="b.code" >
          <span slot-scope="scope">{{data3[`${scope.row.code}_${b.code}`]}}</span>
        </el-table-column>
        <el-table-column label="业务类型维度" >
          <span slot-scope="scope">{{data[`601_${scope.row.code}_`]}}</span>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>
<script>
// import { mapState } from 'vuex'
import SeasonPicker from '../../components/SeasonPicker.vue'
import np from 'number-precision'
np.enableBoundaryChecking(false)

export default {
  components: { SeasonPicker },
  data () {
    return {
      tb: [
        { name: '总计', code: 'total' },
        { name: '答案1', code: 'percent1' },
        { name: '答案2', code: 'percent2' },
        { name: '答案3', code: 'percent3' },
        { name: '答案4', code: 'percent4' },
        { name: '答案5', code: 'percent5' },
        { name: '答案6', code: 'percent6' },
        { name: '答案7', code: 'percent7' },
        { name: '赋值法', code: 'value1' },
        { name: '扩散法1', code: 'value2' },
        { name: '扩散法2', code: 'value3' },
        { name: '景气值', code: 'value' }
      ],

      params: {},
      system: [[], [], []],
      data: {},
      counts: {},
      yewu: [
        { code: 'dy', name: '电影' },
        { code: 'ds', name: '电视剧' },
        { code: 'dm', name: '动漫' },
        { code: 'cy', name: '产业' },
        { code: 'dj', name: '短剧' }
      ],
      bjq: [
        { code: 'sj', name: '与上季度相比' },
        { code: 'tj', name: '与去年同季度相比' },
        { code: 'xj', name: '预计下季度与本季度相比' }
      ],
      data3: {}
    }
  },
  computed: {
  },
  methods: {
    exportExcel () {
      if (!this.params.round) {
        this.$message({ message: '请选择期次', type: 'warning' })
      }
      window.open('/api/index/data/jingqi/excel?round=' + this.params.round, '_blank')
    },
    subQuery () {
      if (!this.params.round) {
        this.$message({ message: '请选择期次', type: 'warning' })
        return
      }
      const w = {
        dy: 0.3,
        ds: 0.42,
        dm: 0.08,
        cy: 0.1,
        dj: 0.1
        // 0.3, ds: 0.42, dm: 0.08, cy: 0.1, dj: 0.1
      }
      this.$request('/index/data/jingqi', { params: { round: this.params.round } })
        .then(({ data, system, counts }) => {
          this.system = system
          this.data = data
          this.counts = counts
          const data3 = {}
          this.bjq.forEach(b => {
            let sum = 0
            this.yewu.forEach(yw => {
              const k = yw.code + '_' + b.code
              const v = np.round((data[`602_${k}`] + data[`603_${k}`] + data[`604_${k}`] + data[`605_${k}`]) / 4, 2)
              if (!isNaN(v)) {
                sum += v * w[yw.code]
                data3[k] = v
              }
            })
            if (sum !== 0) data3[`_${b.code}`] = np.round(sum, 2)
          })
          this.data3 = data3
          this.$forceUpdate()
        })
    }
  },

  created () {

  },
  mounted () {

  }
}
</script>
