<template>
<div>
  <el-card>
    <div slot="header">
      <el-form inline size="small" @submit.native.prevent="subQuery">
        <el-form-item label="报表">
          <el-select v-model="params.tableId" placeholder="所有报表" style="width: 180px">
            <el-option :value="null" label="所有报表" />
            <el-option
              v-for="item in tables"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="填报">
          <el-select v-model="params.status" placeholder="所有" style="width: 90px">
            <el-option :value="null" label="所有" />
            <el-option :value="1" label="已填报" />
            <el-option :value="0" label="未填报" />
          </el-select>
        </el-form-item>
        <el-form-item label="审核">
          <el-select v-model="params.reviewStatus" placeholder="所有" style="width: 120px">
            <el-option :value="null" label="所有" />
            <el-option :value="0" label="未审核" />
            <el-option :value="1" label="审核通过" />
            <el-option :value="-1" label="审核不通过" />
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input placeholder="企业名称" v-model="params.name" style="width: 120px;"></el-input>
        </el-form-item>
        <el-form-item><el-button type="primary" native-type="submit">查询</el-button></el-form-item>
      </el-form>
    </div>
    <el-table
      :data="list"
      style="width: 100%">
      <el-table-column
        label="期次"
        width="120">
        <round slot-scope="scope" :type="scope.row.round.type" :round="scope.row.round.round" />
      </el-table-column>
      <el-table-column
        prop="table.name"
        width="280"
        label="报表">
      </el-table-column>
      <el-table-column label="企业">
        <el-link slot-scope="scope" @click="$showSample(scope.row.sample.id)"><i class="el-icon-link" style="margin-right: 3px; color: #888;"></i>{{scope.row.sample.name}}</el-link>
      </el-table-column>
      <el-table-column label="状态" width="120">
        <template slot-scope="scope" >
          <report-status type="label" :report="scope.row.report.status" :review="scope.row.report.reviewStatus" :comment="scope.row.report.reviewComment" />
        </template>
      </el-table-column>
      <el-table-column label="填报时间" width="130">
        <template slot-scope="scope" v-if="scope.row.report.status !== null">
          <date-time :time="scope.row.report.updateAt || scope.row.report.createAt" show-time style="font-size: 12px; color: #444;"/>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button v-if="scope.row.report.status !== null" type="text" size="medium" icon="el-icon-link" @click="$showReport(scope.row.report.id)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="changeCurrent"
      @size-change="changeSize"
      :current-page.sync="page.current"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="page.size"
      layout="sizes, prev, pager, next, total"
      :total="page.total">
    </el-pagination>
  </el-card>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Progress',
  computed: {
    ...mapState('account', { account: 'user' }),
    ...mapState('progress', ['list', 'error', 'loading', 'page', 'query', 'params']),
    ...mapState(['areas']),
    ...mapState('tables', { tables: ['list'] })
  },
  watch: {
    'params.status' (v) {
      if (v === 0) this.params.reviewStatus = null
    }
  },
  methods: {
    ...mapActions('progress', ['reGet', 'search', 'subQuery', 'changeCurrent', 'changeSize'])
  },
  mounted () {
    this.reGet()
  }
}
</script>

<style scoped>

</style>
